import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import UsersService from '../../core/services/users';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class EditCreateGroupModal extends PureComponent {
    state = {
        users: [],
    };

    componentDidMount() {
        if (this.props.group) {
            this.handleSearch('');
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.handleSearch('');
        }
    }

    handleSearch = async (value) => {
        try {
            var response = await UsersService.search(value, true);
            this.setState({ users: (response || {}).results || [] });
        } catch (ex) {
            console.error(ex);
        }
    };

    render() {
        const { visible, onCancel, onCreate, form, group, confirmLoading } =
            this.props;
        const { getFieldDecorator } = form;

        const title = group ? 'Editar grupo' : 'Crear grupo';

        return (
            <Modal
                title={title}
                visible={visible}
                onOk={onCreate}
                confirmLoading={confirmLoading}
                onCancel={onCancel}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form>
                    <FormItem {...formItemLayout} label="Nombre">
                        {getFieldDecorator('name', {
                            initialValue: group?.name,
                            rules: [
                                {
                                    required: true,
                                    message:
                                        'Debes especificar el nombre del grupo',
                                },
                            ],
                        })(<Input />)}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Administradores">
                        {getFieldDecorator('user_ids', {
                            rules: [],
                            initialValue: group?.users
                                ? group?.users.split(',').map((val) => {
                                      const [id, name] = val.split('::');
                                      return {
                                          key: id,
                                          label: name,
                                      };
                                  })
                                : [],
                        })(
                            <Select
                                showSearch
                                placeholder="Seleccione los usuarios administradores"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                labelInValue={true}
                                notFoundContent="No hay resultados"
                                mode="multiple"
                                onSearch={this.handleSearch}
                            >
                                {this.state.users.map((d) => (
                                    <Select.Option key={d.id}>{`${
                                        d.first_name || ''
                                    } ${d.last_name || ''}`}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(EditCreateGroupModal);
