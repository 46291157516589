import BaseService from './baseService';

export default class PreregistrationsService extends BaseService {
    static async list(query, limit, skip, sortField, sortOrder) {
        return await super.request(
            `/preregistrations?limit=${limit}&skip=${skip}&sortField=${
                sortField ?? ''
            }&sortOrder=${sortOrder ?? ''}&q=${
                query ? encodeURIComponent(query) : ''
            }`
        );
    }

    static async find(preregId) {
        return await super.request(`/preregistrations/${preregId}`);
    }

    static async approve(id, values, observations) {
        return await super.request(`/preregistrations/${id}/approve`, 'PUT', {
            ...values,
            observations,
        });
    }

    static async approveBulk(ids) {
        return await super.request(
            `/preregistrations/_bulk/${JSON.stringify(ids)}/approve`,
            'PUT'
        );
    }

    static async rejectBulk(ids) {
        return await super.request(
            `/preregistrations/_bulk/${JSON.stringify(ids)}/reject`,
            'PUT'
        );
    }

    static async reject(id, observations) {
        return await super.request(`/preregistrations/${id}/reject`, 'PUT', {
            observations,
        });
    }

    static async emailExists(tid, email) {
        const resp = await super.request(
            `/preregistrations/${tid}/email?q=${email}`
        );
        return resp.exists_user;
    }

    static async mobileExists(tid, mobile) {
        const resp = await super.request(
            `/preregistrations/${tid}/mobile?q=${mobile}`
        );
        return resp.exists_user;
    }

    static async getAreas(tid) {
        return await super.request(`/preregistrations/${tid}/areas`);
    }

    static async getPositions(tid) {
        return await super.request(`/preregistrations/${tid}/positions`);
    }

    static async getLocations(tid) {
        return await super.request(`/preregistrations/${tid}/locations`);
    }

    static async getExtendedFieldValues(tid, index) {
        return await super.request(
            `/preregistrations/${tid}/extended_field_values?index=${index}`
        );
    }

    static async listGroups(query, limit, skip) {
        return await super.request(
            `/preregistrations-auth-groups?limit=${limit}&skip=${skip}&q=${
                query ? encodeURIComponent(query) : ''
            }`
        );
    }

    static async findGroup(id) {
        return await super.request(`/preregistrations-auth-groups/${id}`);
    }

    static async updateGroup(id, name, user_ids) {
        return await super.request(
            `/preregistrations-auth-groups/${id}`,
            'PUT',
            { name, user_ids }
        );
    }

    static async deleteGroup(id) {
        return await super.request(
            `/preregistrations-auth-groups/${id}`,
            'DELETE'
        );
    }

    static async createGroup(name, user_ids) {
        return await super.request(`/preregistrations-auth-groups`, 'POST', {
            name,
            user_ids,
        });
    }
}
