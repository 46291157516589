import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Table,
    Button,
    Modal,
    Dropdown,
    Menu,
    Icon,
    Input,
    message,
} from 'antd';

import './groups.page.css';
import PreregistrationsService from '../../core/services/preregistrations';
import EditCreateGroupModal from './edit-create-group.modal';
import DateDisplay from '../../common/components/date';

const confirm = Modal.confirm;

class GroupsPage extends PureComponent {
    state = {
        selectedRowKeys: [],
        pagination: {},
        modalTitle: 'Crear grupo',
        modalVisible: false,
        editingGroup: null,
        query: null,
        saving: false,
        error: null,
    };

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Nombre',
                dataIndex: 'name',
            },
            {
                title: 'Administradores',
                dataIndex: 'user_count',
            },
            {
                width: 150,
                title: 'Creado',
                dataIndex: 'created_at',
                render: (val) => {
                    return <DateDisplay relative>{val}</DateDisplay>;
                },
            },
        ];

        if (this.canEdit()) {
            this.columns.push({
                title: '',
                width: 40,
                render: (_, record) => (
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={({ item, key }) =>
                                    this.handleTableAction(key, record)
                                }
                            >
                                <Menu.Item key="edit">Editar</Menu.Item>
                                <Menu.Item key="delete">Eliminar</Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <Button type="link" className="tbl-action">
                            <Icon type="ellipsis" />
                        </Button>
                    </Dropdown>
                ),
            });
        }
    }

    canEdit = () => {
        return this.props.auth.user.role === 'admin';
    };

    async componentDidMount() {
        await this.queryGroups(null, 1);
    }

    deleteGroups = async (groupIds) => {
        try {
            this.setState({ deleting: true });
            for (const group of groupIds) {
                await PreregistrationsService.deleteGroup(group);
            }
            message.success('Grupos eliminados correctamente');
            await this.refreshTable();
        } catch (error) {
            message.error('Ocurrio un error al eliminar los grupos');
        } finally {
            this.setState({ deleting: false });
        }
    };

    createGroup = async (name, user_ids) => {
        try {
            this.setState({ saving: true });
            await PreregistrationsService.createGroup(name, user_ids);
            message.success('El grupo se ha creado correctamente');
            await this.refreshTable();
        } catch (error) {
            message.error('Ocurrio un error al crear el grupo');
        } finally {
            this.setState({ saving: false });
        }
    };

    updateGroup = async (name, user_ids) => {
        try {
            this.setState({ saving: true });
            await PreregistrationsService.updateGroup(
                this.state.editingGroup.id,
                name,
                user_ids
            );
            message.success('El grupo se ha actualizado correctamente');
            await this.refreshTable();
        } catch (error) {
            message.error('Ocurrio un error al actualizar el grupo');
        } finally {
            this.setState({ saving: false });
        }
    };

    queryGroups = async (q, page) => {
        try {
            this.setState({ loading: true });
            const response = await PreregistrationsService.listGroups(
                q,
                10,
                10 * (page - 1)
            );

            this.setState({
                groups: response.results,
                pagination: {
                    ...this.state.pagination,
                    total: response.pagination.total,
                    pageSize: response.pagination.limit,
                },
            });
        } catch (error) {
            message.error('Ocurrio un error al consultar los grupos');
        } finally {
            this.setState({ loading: false });
        }
    };

    refreshTable = async () => {
        const form = this.formRef.props.form;
        form.resetFields();
        this.hideModal();
        await this.queryGroups(
            this.state.query ?? '',
            this.state.pagination.current ?? 1
        );
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    handleTableChange = async (pagination) => {
        this.setState({ pagination });
        await this.queryGroups(this.state.query, this.state.pagination.current);
    };

    getCheckboxProps = (record) => {
        return {
            disabled: false, //record.disabled,
            name: record.name,
        };
    };

    doSearch = (value) => {
        this.setState(
            {
                query: value,
                pagination: { ...this.state.pagination, current: 1 },
            },
            async () => await this.queryGroups(this.state.query, 1)
        );
    };

    showDeleteConfirm = (groupIds) => {
        var count =
            groupIds.length === 1
                ? 'el grupo seleccionado'
                : `los ${groupIds.length} grupos seleccionados`;
        confirm({
            title: `¿Estás seguro que deseas eliminar ${count}?`,
            content: '',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => await this.deleteGroups(groupIds),
        });
    };

    handleTableAction = (action, group) => {
        switch (action) {
            case 'edit':
                this.openEdit(group);
                break;
            case 'delete':
                this.showDeleteConfirm([group.id]);
                break;
            default:
                console.warn('unknown action');
        }
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: this.getCheckboxProps,
        };

        const hasSelected = this.state.selectedRowKeys.length > 0;
        return (
            <div className="content-internal">
                <Input.Search
                    placeholder="Buscar..."
                    onSearch={this.doSearch}
                    style={{ width: 200, float: 'left' }}
                />
                {this.canEdit() && (
                    <div className="top-buttons">
                        <Button.Group>
                            {hasSelected && (
                                <Button
                                    icon="-delete"
                                    onClick={() =>
                                        this.showDeleteConfirm(
                                            this.state.selectedRowKeys
                                        )
                                    }
                                >
                                    Eliminar grupos
                                </Button>
                            )}
                            <Button
                                type="primary"
                                icon="plus"
                                onClick={this.openCreate}
                            >
                                Nuevo grupo
                            </Button>
                        </Button.Group>
                    </div>
                )}

                <Table
                    {...this.state}
                    loading={this.state.loading}
                    className="striped-rows"
                    onChange={this.handleTableChange}
                    pagination={this.state.pagination}
                    rowKey={(r) => r.id}
                    rowSelection={this.canEdit() ? rowSelection : undefined}
                    columns={this.columns}
                    dataSource={this.state.groups}
                    size="small"
                    tableLayout="fixed"
                />

                <EditCreateGroupModal
                    wrappedComponentRef={this.saveFormRef}
                    group={this.state.editingGroup}
                    visible={this.state.modalVisible}
                    confirmLoading={this.props.saving}
                    onCancel={this.hideModal}
                    onCreate={this.handleCreate}
                />
            </div>
        );
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    openEdit = (group) => {
        this.setState({ editingGroup: group, modalVisible: true });
    };

    openCreate = () => {
        this.setState({ modalVisible: true, editingGroup: null });
    };

    hideModal = () => {
        this.setState({ modalVisible: false });
    };

    handleCreate = () => {
        const form = this.formRef.props.form;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const user_ids = values.user_ids.map((user) => user.key);
            if (this.state.editingGroup) {
                await this.updateGroup(values.name, user_ids);
            } else {
                await this.createGroup(values.name, user_ids);
            }
        });
    };
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
